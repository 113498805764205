import * as React from 'react';
import { Link } from 'gatsby';

// styles
const pageStyles = {
	padding: '96px',
	fontFamily: 'Quicksand',
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};

const paragraphStyles = {
	marginBottom: 48,
};
const codeStyles = {
	padding: 4,
	backgroundColor: '#FFF',
	fontSize: '1.25rem',
	borderRadius: 4,
};

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>Página no encontrada</title>
			<h1 style={headingStyles}>Página no encontrada</h1>
		</main>
	);
};

export default NotFoundPage;
